import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Card from "components/cards/TabCardGrid.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { useLocation } from "react-router-dom";

export default () => {
  let location = useLocation();
  useEffect(()=> {
    if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1))
        if (elem) {
            elem.scrollIntoView({behavior: "smooth"})
        }
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location]);
  return (
  <AnimationRevealPage>
    <Hero />
    <MainFeature />
    <Features />
    <Card />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
  );
};
