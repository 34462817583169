import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import emailjs from 'emailjs-com';


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Select = tw.select`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Fale connosco",
  heading = <>Resposta em menos de <span tw="text-primary-500" style={{color: "#c50505"}}>24h</span><wbr/>!</>,
  description = <>provend.pt@gmail.com | (+351) 932 596 113</>,
  submitButtonText = "Enviar",
  textOnLeft = true,
}) => {
  const [textMsg, setTextMsg] = useState('');
  const [textState, setTextState] = useState(false);
  const [textType, setTextType] = useState(true);

  const [nome, setNome]= useState('');
  const [email, setEmail]= useState('');
  const [mess, setMess] = useState('');
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  function formAction(e){
    e.preventDefault();

    if(nome == ''){
      setTextType(false);
      setTextMsg("Por favor preencha o nome");
      setTextState(true);
    }else if(email == ''){
      setTextType(false);
      setTextMsg("Por favor preencha o email");
      setTextState(true);
    }else if(mess == ''){
      setTextType(false);
      setTextMsg("Por favor escreva uma mensagem");
      setTextState(true);
    }else{
      emailjs.sendForm('gmailMessage', 'template_2slljme', e.target, 'user_0jP9eaJlu0vsZapZQaS72')
      .then((result) => {
        setTextType(true);
        setTextMsg("Mesnsagem enviada com sucesso");
        console.log(result.text);
      }, (error) => {
        setTextType(false);
        setTextMsg("Parece que ocorreu um erro, tente mais tarde");
        console.log(error.text);
      });
      setTextState(true);

      setNome('');
      setEmail('');
      setMess('');
      e.target.reset();
    }
  }
  
  return (
    <Container id="contactoDiv">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading style={{color: "#c50505"}}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <br />
            {
              textState &&
                <div style={{backgroundColor: !textType ? "#c50505" : "#00ce22", paddingLeft: 2,
                  paddingRight: 2,
                  paddingTop: 1,
                  paddingBottom: 1,
                  width: '50%', borderRadius: 5,textAlign: 'center'}}>
                    <p style={{color:'white', fontWeight: 'bold', padding: 10}}>{textMsg}</p>
              </div>
            }
            
            <Form onSubmit={formAction}>
              <Input type="text" name="from_name" placeholder="Nome"
              value={nome}
              onChange={e => setNome(e.value)}/>
              <Input type="email" name="youremail" placeholder="Email" 
              value={email}
              onChange={e => setEmail(e.value)}/>
              <Select name="from_type">
                <option value='Nenhum'>Selecione...</option>
                <option value='Empresarial'>Empresarial</option>
                <option value='Público'>Público</option>
                <option value='Particular'>Particular</option>
                <option value='Outro'>Outro</option>
              </Select>
              <Input type="text" name="from_contact" placeholder="Contacto                                                      (Facultativo)" />
              <Textarea name="message" placeholder="A sua menssagem aqui" 
              value={mess}
              onChange={e => setMess(e.value)}/>
              <SubmitButton type="submit" style={{backgroundColor: "#c50505"}}>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
