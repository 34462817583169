import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<><HighlightedText>VHM</HighlightedText></>}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Website"
      />
      <MainFeature
        heading={
          <>
            <HighlightedText>SORVENDING</HighlightedText>
          </>
        }
        description={
          <>
            <br />
            Rua Miguel Torga, nº16, <br />
            7400-273 <br />
            Ponte de Sor
            <br />
            <br />
            +351 919 687 576
            <br />
            <br />
            sorvending@gmail.com
          </>
        }
        primaryButtonText={"Website"}
        primaryButtonUrl={"http://www.sorvending.com"}
        buttonRounded={false}
        textOnLeft={false}
        imageSrc={
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3088.7878205892866!2d-8.05232738467939!3d39.27038057951482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd183bb3542bcc19%3A0x7c29cd234a41a8fe!2sR.%20Miguel%20Torga%2C%20Ponte%20de%20Sor!5e0!3m2!1spt-PT!2spt!4v1614811861742!5m2!1spt-PT!2spt" style={{border:0, width:"100%", height:400, borderRadius: 40}} allowfullscreen="" loading="lazy"></iframe>
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        haveButton={true}
      />
      <MainFeature
        heading={
          <>
            <HighlightedText>OFFICE BREAK</HighlightedText>
          </>
        }
        description={
          <>
            <br />
            Urb. Choilo Sul, Travessa Diogo Teive Lote 202<br />
            2925-636<br />
             Brejos De Azeitão
            <br />
            <br />
            +351 968 693 600
            <br />
            <br />
            renato.bento@sapo.pt
          </>
        }
        imageSrc={
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115415.3597500233!2d-9.084551822750116!3d38.5163199203227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd194f60c596d0db%3A0x7caf7095c3cfedf4!2sR.%20do%20Choilo!5e0!3m2!1spt-PT!2spt!4v1614811918593!5m2!1spt-PT!2spt" style={{border:0, width:"100%", height:400, borderRadius: 40}} allowfullscreen="" loading="lazy"></iframe>
        }
        buttonRounded={false}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <MainFeature
        heading={
          <>
            <HighlightedText>Top Machines</HighlightedText>
          </>
        }
        description={
          <>
            <br />
            {/* Rua Miguel Torga, nº16, <br />
            7400-273 <br />
            Ponte de Sor */}
            {/* <br />
            <br /> */}
            +351 965 623 692
            <br />
            <br />
            topmachines.vending@gmail.com
          </>
        }
        primaryButtonText={"Facebook"}
        primaryButtonUrl={"https://www.facebook.com/Top-Machines-110249007286895"}
        buttonRounded={false}
        textOnLeft={false}
        imageSrc={
          <iframe src="https://www.google.com/maps/embed" style={{border:0, width:"100%", height:400, borderRadius: 40}} allowfullscreen="" loading="lazy"></iframe>
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        haveButton={true}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
