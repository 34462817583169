import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "../../images/provendfinal.png";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

import tumb from "../../images/tumb.webp"; 

import { withRouter } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";

const StyledHeader = styled(Header)`
  ${tw`pt-8 w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  @media (max-width: 767px) {
    .anossa {
      font-size: 27px !important;
      margin-top: 140px;
    }
  }
  @media (max-width: 400px) {
    .anossa {
      font-size: 22px !important;
      margin-top: 170px;
    }
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;


export default () => {
  const ButtonEx = withRouter(({ history }) => (
    <Link
      onClick={() => { history.push('/parceiros') }}
    >
      Parceiros
    </Link>
  ));
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#" >
        <Link to="provendDiv"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          >Provend</Link> 
      </NavLink>
      <NavLink href="#">
        <Link to="servicosDiv"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}>Serviços</Link>
      </NavLink>
      <NavLink href="#">
        <Link to="equipamentosDiv"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}>Equipamentos</Link>
      </NavLink>
      <NavLink href="#">
        <ButtonEx />
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#" style={{backgroundColor: "#c50505"}}>
        <Link to="contactoDiv"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}>(+351) 932 596 113</Link>
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container style={{backgroundImage: `url(${tumb})`, }} id="headerDiv">
      <OpacityOverlay style={{backgroundColor: "rgb(0, 0, 0, 0.3)"}}/>
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
            {/* <img src={logo} alt="logo" style={{width: "70%", marginLeft: "auto",marginRight: "auto"}}/> */}
              <br />
              <br />
              <br />

              <p className="anossa" style={{fontSize: 45, fontFamily: "Cocon"}}>A nossa proximidade... faz a diferença!!!</p>
              
              <p className="amaqui" style={{fontSize: 25, fontFamily: "Cocon"}}>Máquinas Automáticas</p>
          </Heading>
          {/* <PrimaryAction>Search Events Near Me</PrimaryAction> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
