import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line

import './solodash.css';

import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support.svg";
import ShieldIconImage from "../../images/coffee-cup.svg";
import CustomizeIconImage from "../../images/parachute.svg";
import FastIconImage from "../../images/vending-machinegg.svg";
import ReliableIconImage from "../../images/potato-chips.svg";
import SimpleIconImage from "../../images/beer.svg";

const Container = tw.div`relative`;

export default () => {
  return (
    <Container id="servicosDiv">
      <div id="fh5co-featured" >
			  <div class="container">
          <div className="row text-center fh5co-heading row-padded">
            <div className="col-md-8 col-md-offset-2"> 
              <h2 className="heading to-animate">Serviços</h2>
              <p className="sub-heading to-animate"></p>
            </div>
          </div>
          <div className="row" id="servicos_id">
            <div className="fh5co-grid">
              <div className="fh5co-v-half to-animate-2 imagenservicos1princ" >
                {/* style="background-image: url(images/servicio-tecnico-averias.jpg)" */}
                <div className="imagenservicos1 fh5co-v-col-2 fh5co-bg-img averias" ></div>
                <div className="imagenservicos1 fh5co-v-col-2 fh5co-text fh5co-special-1 arrow-left">
                  <h2>Assistência</h2>
                  <span className="pricing">7/7</span>
                  <p>Asseguramos uma assistência técnica, profissional e rápida a todos os nossos clientes.</p>
                </div>
              </div>
              <div className="fh5co-v-half">
                <div className="fh5co-h-row-2 to-animate-2 imagenservicos2princ">
                {/* style="background-image: url(sandes/cafe1.png)" */}
                  <div className="imagemservicos6 fh5co-v-col-2 fh5co-bg-img cafe1" ></div>
                  <div className="imagemservicos6 fh5co-v-col-2 fh5co-text arrow-left">
                    <h2>Bebidas Quentes</h2>
                    <span className="pricing">Café</span>
                    <p>Garantimos café sempre de qualidade!</p>
                  </div>
                </div>
                <div className="fh5co-h-row-2 fh5co-reversed to-animate-2 imagenservicos3princ">
                {/* style="background-image: url(sandes/sandes1.png)" */}
                  <div className="imagemservicos5 fh5co-v-col-2 fh5co-bg-img sandes1" ></div>
                  <div className="imagemservicos5 fh5co-v-col-2 fh5co-text arrow-right">
                    <h2>Abastecimento</h2>
                    <span className="pricing">Sandes</span>
                    <p>Dispomos de uma grande variedade de sandes e pastelaria fresca.</p>
                  </div>
                </div>
              </div>

              <div className="fh5co-v-half">
                <div className="fh5co-h-row-2 fh5co-reversed to-animate-2 imagenservicos4princ">
                {/* style="background-image: url(images/stock.jpg)" */}
                  <div className="imagemservicos2 fh5co-v-col-2 fh5co-bg-img stock" ></div>
                  <div className="imagemservicos2 fh5co-v-col-2 fh5co-text arrow-right">
                    <h2>Snacks</h2>
                    <span className="pricing">Produtos</span>
                    <p>Bolachas, chocolates, pastelaria seca, barras de cereais, etc...</p>
                  </div>
                </div>
                <div className="fh5co-h-row-2 to-animate-2 imagenservicos5princ">
                {/* style="background-image: url(images/latas_de_refrigerantes.jpg)" */}
                  <div className="imagemservicos3 fh5co-v-col-2 fh5co-bg-img latas_de_refrigerantes"  ></div>
                  <div className="imagemservicos3 fh5co-v-col-2 fh5co-text arrow-left">
                    <h2>Bebidas Frias</h2>
                      <span className="pricing">Bebidas</span>
                    <p>Trabalhamos com uma ampla variedade de refrigerantes e néctares.</p>
                  </div>
                </div>
              </div>
              <div className="fh5co-v-half to-animate-2 imagenservicos6princ">
              {/* style="background-image: url(images/maquina55.png)" */}
                <div className="imagemservicos4 fh5co-v-col-2 fh5co-bg-img maquina55" ></div>
                <div className="imagemservicos4 fh5co-v-col-2 fh5co-text fh5co-special-1 arrow-left">
                  <h2>Equipamentos</h2>
                  {/* style="font-size: 20px;" */}
                  <span className="pricing">Máquinas Automáticas</span>
                  <br/>
                    <p>Temos a máquina adequada para a sua empresa.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
